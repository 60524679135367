import React from "react"
import { StaticQuery } from "gatsby"

import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"

import Navigation from "../components/navigation"

import "../styles/style.css"

class Layout extends React.Component {
  render() {
    const { location, children } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                menuLinks {
                  en {
                    link
                    name
                  }
                  pt {
                    link
                    name
                  }
                }
                languages {
                  defaultLangKey
                  langs
                }
              }
            }
          }
        `}
        render={data => {
          const url = location.pathname
          const { langs, defaultLangKey } = data.site.siteMetadata.languages
          const langKey = getCurrentLangKey(langs, defaultLangKey, url)
          const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
          const langsMenu = getLangs(
            langs,
            langKey,
            getUrlForLang(homeLink, url)
          ).map(item => ({
            ...item,
            link: item.link.replace(`/${defaultLangKey}/`, "/"),
          }))

          return (
            <>
              <Navigation
                menuLinks={data.site.siteMetadata.menuLinks[langKey]}
                siteTitle={data.site.siteMetadata.title}
                currentLangKey={langKey}
                langs={langsMenu}
              />
              {children}
              <footer className="text-center">
                © {new Date().getFullYear()}, Built with
                {` `}
                <a href="https://www.gatsbyjs.org">Gatsby</a>
              </footer>
            </>
          )
        }}
      />
    )
  }
}

export default Layout

import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import MenuMobile from "./menuMobile"
import { FaBars } from "react-icons/fa"
import { GiUsaFlag, GiBrazilFlag } from "react-icons/gi"

const Header = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menuLinks {
            en {
              link
              name
            }
            pt {
              link
              name
            }
          }
        }
      }
      brazilFlag: file(absolutePath: { regex: "/brazil-flag.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      usUkFlag: file(absolutePath: { regex: "/us-uk-flag.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const mobileLinks = [...site.data.menuLinks[props.currentLangKey]]

  const flagsMapping = {
    en: <GiUsaFlag className="inline-block" />,
    pt: <GiBrazilFlag className="inline-block" />,
  }

  const languageLinks = props.langs.map(lang => (
    <Link
      className="ml-6 sm:ml-8 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-200 transition duration-150 ease-in-out"
      to={lang.link}
      key={lang.langKey}
    >
      {flagsMapping[lang.langKey]}
      &nbsp;{lang.langKey}
    </Link>
  ))

  return (
    <div className="container pt-6 pb-12 md:pt-12">
      <div className="flex justify-between items-center">
        <Link
          className="no-underline text-xl md:text-4xl font-bold"
          to={
            site.data.menuLinks[props.currentLangKey].find(
              link => link.name === "Home"
            ).link
          }
        >
          <h1 className="no-underline">Felipe Galvao</h1>
        </Link>

        <div className="mr-4 inline-block sm:hidden">{languageLinks}</div>
        <button
          className="sm:hidden"
          onClick={() => setIsMenuOpen(true)}
          aria-label="Open Menu"
        >
          <FaBars className="h-6 w-auto text-gray-900 fill-current -mt-1" />
        </button>

        <div className="hidden sm:block">
          <div className="mr-6 inline-block">{languageLinks}</div>
          <div className="border-solid border-l-2 border-red-800 inline-block">
            {site.data.menuLinks[props.currentLangKey].map((link, key) => (
              <Link
                key={`menu_desktop_link${key}`}
                className="ml-6 sm:ml-8 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-200 transition duration-150 ease-in-out"
                activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
                to={link.link}
              >
                {link.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <MenuMobile
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        links={mobileLinks}
      />
    </div>
  )
}

export default Header
